/* @import url("https://fonts.googleapis.com/css2?family=Edu+VIC+WA+NT+Beginner&family=Sora:wght@500&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Public+Sans&display=swap'); */

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --main-color: #02297b;
  --secondary-color: white;
  --third-color: #2b2b2b;
  --black-color: black;
  --pink-color: #c01479;
  --grey-color: #c7c6c7;
  --bg-color: #f5f5f5;
  --primary-font-family: sans-serif;
  --text-font: "Inter", sans-serif;
  --title: 16px;
  --sub-title: 14px;
}

body {
  background-color: var(--bg-color);
}

.content-area {
  padding-top: 135px;
}

section,
article,
input,
div,
textarea,
button,
img,
form {
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

img {
  max-width: 100%;
}

/* KONTAKTA OSS CSS */

.form {
  font-family: var(--primary-font-family);
  margin: auto;
  padding: 0rem 2rem;
  max-width: 700px;
}

.rubrik {
  text-align: center;
  font-size: 25px;
  padding: 2rem 0rem;
}

.text {
  font-size: 18px;
}

.input-content {
  margin-top: 30px;
}

input,
select,
textarea {
  width: 100%;
  margin-top: 5px;
  border: 2px solid #b9b9b9;
  border-radius: 5px;
      font-family: var(--primary-font-family);
}

select {
    padding: 13px 20px;
  appearance: none;
  background-color: white;
  color: black; 
  font-size: 15px; 
}
textarea {
  padding: 11px 16px;
  resize: none;
      font-size: 16px;
}

.send-btn {
  margin-top: 30px;
}
